<template>
  <footer>
    <div class="footer-bar">
      <b-container fluid>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-link to="/impresum/">
              Impresum
            </b-link>
          </b-col>
          <b-col cols="auto">
            <b-link to="/obchodni-zastoupeni/">
              Kontakt
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'JbFooter'
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/partials/footer";
</style>
